<script>

    import appConfig from "@/app.config.json";
    import helper from "@/assets/helpers/helper";
    import Paginate from "../../components/pagination";

    export default {
        components: {Paginate},
        page: {
            title: "Profile University",
            meta: [{name: "description", content: appConfig.description}]
        },
        name: 'ProfilUniversity',
        data() {
            return {
                title: "Profile University",
                profil_info:{},
                students:[],
                count_students:0,
                none:'none',
                current_page: 1,
                last_page_url: 6,
                isLoad:false,
                load:false,
                currentSort:'score',
                currentSortDir:'descending',
            };
        },
        created(){
            this.loadUniversity()
            this.loadStudents(1)
        },
        computed:{
            students_sorted:function() {
                let students = this.students;
                return students.sort((a,b) => {
                    let modifier = 1;
                    if(this.currentSortDir === 'descending') modifier = -1;
                    if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                });
            }
        },
        methods: {
            async loadUniversity() {
                this.profil_info = await helper.getItem('universities', this.$route.params.id);
                this.load = true
            },
            async loadStudents(page) {
                let a = await helper.loadData('universities/'+this.$route.params.id+'/students',page);
                this.students = a.students.data
                this.count_students=a.count_students
                this.current_page = a.students.current_page
                this.last_page_url = a.students.last_page;
                this.isLoad = true
            },
            sort:function(s) {
                //if s == current sort, reverse
                if(s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir==='ascending'?'descending':'ascending';
                }
                this.currentSort = s;
            },
        }
    };
</script>

<template>

        <div class="row mt-5" >
            <div class="col-xl-8 mx-auto">

                <div class="card">
                    <h1 class="py-4 text-center">{{profil_info.name}}</h1>
                    <b-skeleton v-if="!load" width="40%" class="m-auto"></b-skeleton>
                    <div class="card-body">
                        <div class="row my-3">
                            <div class="col-xl-3 text-center">

                                <b-avatar :src="profil_info.logo" square size="9em"></b-avatar>
                            </div>
                            <div class="col-xl-9">
                                <ul class="verti-timeline list-unstyled">
                                    <li class="event-list">
                                        <div class="event-timeline-dot">
                                            <i class="bx bx-right-arrow-circle"></i>
                                        </div>
                                        <div class="media">
                                            <div class="mr-3">
                                                <i class="bx bx-globe h2 text-primary"></i>
                                            </div>
                                            <div class="media-body">
                                                <div>
                                                    <h5 class="font-size-14">{{$t('universities.website')}}</h5>
                                                    <b-skeleton v-if="!load" class="mb-2"></b-skeleton>
                                                    <b-skeleton v-if="!load" width="75%"></b-skeleton>
                                                    <a class="text-muted" :href="profil_info.website_url" target="_blank">{{profil_info.website_url}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="event-list">
                                        <div class="event-timeline-dot">
                                            <i class="bx bx-right-arrow-circle"></i>
                                        </div>
                                        <div class="media">
                                            <div class="mr-3">
                                                <i class="bx bx-receipt h2 text-primary"></i>
                                            </div>
                                            <div class="media-body">
                                                <div>
                                                    <h5 class="font-size-14">{{$t('universities.desc')}}</h5>
                                                    <b-skeleton v-if="!load" class="mb-2"></b-skeleton>
                                                    <b-skeleton v-if="!load" class="mb-2"></b-skeleton>
                                                    <b-skeleton v-if="!load"  width="75%"></b-skeleton>
                                                    <p class="text-muted">
                                                        {{profil_info.description}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="event-list">
                                        <div class="event-timeline-dot">
                                            <i class="bx bx-right-arrow-circle"></i>
                                        </div>
                                        <div class="media">
                                            <div class="mr-3">
                                                <i class="bx bx-map-pin h2 text-primary"></i>
                                            </div>
                                            <div class="media-body">
                                                <div>
                                                    <h5 class="font-size-14">{{$t('universities.address')}}</h5>
                                                    <b-skeleton v-if="!load" class="mb-2"></b-skeleton>
                                                    <b-skeleton v-if="!load"  width="25%"></b-skeleton>
                                                    <p class="text-muted">
                                                        {{profil_info.address}}
                                                    </p>
                                                    <h6 v-if="profil_info.country">{{profil_info.country}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>


                    </div>
                </div>
            </div>


        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    <h1 class="card-title mb-4" >
                        <i class="bx bxs-graduation mr-2"></i>{{$t('universities.students')}}</h1>
                    <div >
                    <div class="text-center" v-if="isLoad">
                        <h5>{{count_students}} Students</h5>
                        <span class="text-muted mt-2" v-if="students.length == 0">{{$t('universities.no_students')}}</span>
                    </div>
                        <div v-if="!isLoad">
                            <b-skeleton-table :rows="4" :columns="3"></b-skeleton-table>
                            <b-skeleton></b-skeleton>
                        </div>

                        <div class="row mt-4" v-if="students.length">
                            <table class=" tab table b-table text-center " role="table">
                                <thead>
                                <tr>
                                    <th scope="col" @click="sort('username')" role="columnheader" :aria-sort="(currentSort == 'username')?currentSortDir:none">{{$t('profil_user.username')}}</th>
                                    <th scope="col" @click="sort('score')" role="columnheader" :aria-sort="(currentSort == 'score')?currentSortDir:none">{{$t('profil_user.score')}}</th>
                                    <th scope="col" @click="sort('level')" role="columnheader" :aria-sort="(currentSort == 'level')?currentSortDir:none">{{$t('profil_user.level')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="data of students_sorted" :key="data.id">
                                  <td :data-label="$t('profil_user.username')"><router-link  class="h5ink"  :to="{name:'ProfilUser',params:{id:data.id}}">{{data.username}}</router-link></td>
                                    <td :data-label="$t('profil_user.score')"> {{data.score}}</td>
                                    <td :data-label="$t('profil_user.level')"> {{data.level}}</td>
                                </tr>
                                </tbody>
                            </table>
                            <paginate class="mx-auto my-3" :current_page=current_page :last_page_url=last_page_url
                                      v-on:change-page="loadStudents"/>
                        </div>
                          </div>
                </div>
            </div>
        </div>

        </div>

</template>